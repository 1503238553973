import './components'
import './lib/polyfills'
import './lazyload'

addEventListener('DOMContentLoaded', (event) => {
    initializeForms()
    initMenu()
})

// Mark a <form> with the class `busy` when it's being submitted.
function initializeForms() {
    const forms = document.querySelectorAll('form')
    for (const form of forms) {
        form.addEventListener('submit', function (event) {
            if (document.activeElement) {
                document.activeElement.blur()
                console.log(document.activeElement)
            }

            this.classList.add('busy')
        })
    }
}

function initMenu() {
    const header = document.querySelector('header')

    addEventListener('scroll', (event) => {
        const y = window.scrollY || document.body.scrollTop
        const className = 'compact'

        if (y > 50 && !header.classList.contains(className)) {
            header.classList.add(className)
        }
        else if (y <= 50 && header.classList.contains(className)) {
            header.classList.remove(className)
        }
    })
}